import React from 'react';

import Pages from './pages';
import './assets/styles/index.scss';

function App() {
    return <Pages />;
}

export default App;
